// src/components/Hero.tsx

import React, { FunctionComponent } from 'react';
import styles from './hero.module.scss';
import hero from '../assets/heroTitle.svg';

const Hero: FunctionComponent = () => {
  return (
    <div className={styles.heroWrapper}>
      <img className={styles.heroImage} src={hero} alt="Hero Title" />
    </div>
  );
};

export default Hero;
