// src/animation/animation.tsx

import React from 'react';
import './animation.scss';

const Animation: React.FC = () => {
  return (
    <div className="animation-container">
      <div className="spinning-ovals">
        <div className="oval oval1"></div>
        <div className="oval oval2"></div>
        <div className="oval oval3"></div>
        <div className="oval oval4"></div>
      </div>
    </div>
  );
};

export default Animation;
