// src/App.tsx

import React from 'react';
import Animation from './animation/animation';
import Navbar from './Navbar';
import Hero from './hero/hero';
const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Animation />
    </div>
  );
};

export default App;
