import React from 'react';
import './Navbar.scss';
import logo from '../assets/sclogo.svg';
const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <img className="logo" alt="" src={logo} />
      {/* <div className="nav-buttons">
        <button className="nav-button">Button 1</button>
        <button className="nav-button clear-button">Button 2</button>
        <button className="nav-button">Button 3</button>
      </div> */}
    </nav>
  );
};

export default Navbar;
